body{
    padding: 0;
    margin: 0;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.App{
    text-align: center;
}

@media only screen and (min-width: 992px){
    .ui.card.res{
        width: 35rem;
    }
}

.navbar{
    background-color: #003248;
}

.navbar-img{
    margin: auto;
    padding: 0.9rem 2rem;
}